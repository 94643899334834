<template>
  <div>
    <vs-input @input="emitUrl" class="w-full" v-model="url"/>
  </div>
</template>

<script>
export default {
  data: () => ({
    url: ''
  }),
  methods: {
    emitUrl(input) {
      this.$emit('input', this.$normalizeUrl(input))
    }
  }
}
</script>

<style lang="scss">
._url-input-anchor {
  display: none;
}
</style>
