var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-wizard",
        {
          ref: "formWizard",
          attrs: {
            color: "rgba(var(--vs-primary), 1)",
            title: null,
            subtitle: null,
            hideButtons: true,
            "step-size": "xs",
            enableAllNav: true,
          },
          on: { "on-change": _vm.tabChanged },
        },
        [
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: {
                title: _vm.$t("conteudo"),
                icon: "feather icon-file-text",
              },
            },
            [
              _c("vx-card", [
                _c("div", { staticClass: "grid grid-cols-12" }, [
                  _c("div", { staticClass: "col-span-8" }, [
                    _c("div", { staticClass: "vx-row mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "col-span-3 p- font-medium text-grey",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("tipo")) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex gap-2 flex-grow-0 mt-2" },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "flex ml-2 cursor-pointer",
                                    _vm.type === "M"
                                      ? "edu-switch-selected"
                                      : "edu-switch",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      _vm.type = "M"
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "h-6 w-6 text-success",
                                    attrs: { icon: "book-reader" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-success ml-2" },
                                    [_vm._v(_vm._s(_vm.$t("content_types.M")))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class: [
                                    "flex cursor-pointer",
                                    _vm.type === "A"
                                      ? "edu-switch-selected"
                                      : "edu-switch",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      _vm.type = "A"
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "h-6 w-6 text-warning",
                                    attrs: { icon: "file-alt" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-warning ml-2" },
                                    [_vm._v(_vm._s(_vm.$t("content_types.A")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate:name",
                                value: "required|max:191",
                                expression: "'required|max:191'",
                                arg: "name",
                              },
                            ],
                            staticClass: "w-full required",
                            attrs: {
                              name: "title",
                              id: "model_title",
                              label: _vm.$t("classes_title"),
                            },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("title"),
                                  expression: "errors.has('title')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("title")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col sm:w-1/1 w-full mb-2" },
                        [
                          _c("label", { attrs: { id: "description" } }, [
                            _vm._v(_vm._s(_vm.$t("instrucoes"))),
                          ]),
                          _c("ComplexEditor", {
                            attrs: {
                              questionnaire_id: _vm.contentId,
                              editor_data: _vm.editorData,
                              placeholder: _vm.$t("digite-aqui"),
                            },
                            on: {
                              "update:questionnaire_id": function ($event) {
                                _vm.contentId = $event
                              },
                              "update:editor_data": function ($event) {
                                _vm.editorData = $event
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("description"),
                                  expression: "errors.has('description')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("description")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "flex mt-2" }, [
                      _c(
                        "div",
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.bottom-start",
                                value: {
                                  content: "Enviar arquivo do seu computador.",
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{\n                    content: 'Enviar arquivo do seu computador.',\n                    delay: { show: 1000, hide: 100 }\n                  }",
                                modifiers: { "bottom-start": true },
                              },
                            ],
                            staticClass: "cursor-pointer",
                            attrs: {
                              icon: "UploadIcon",
                              svgClasses: "stroke-current w-6 h-6",
                            },
                            on: { click: _vm.showUploadFile },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.bottom-start",
                                value: {
                                  content: "Adicione um link da internet.",
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{\n                    content: 'Adicione um link da internet.',\n                    delay: { show: 1000, hide: 100 }\n                  }",
                                modifiers: { "bottom-start": true },
                              },
                            ],
                            staticClass: "cursor-pointer pl-4",
                            attrs: {
                              icon: "LinkIcon",
                              svgClasses: "stroke-current w-6 h-6",
                            },
                            on: { click: _vm.showUploadLinkModal },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "EduGooglePicker",
                            {
                              attrs: {
                                clientId:
                                  "122025529505-sl56h9ddcpst6gcb7hcu94m1vtbbg630.apps.googleusercontent.com",
                                developerKey:
                                  "AIzaSyASQbPfm2FycUmhBTUnctF9RxP1o3j7FDc",
                                scope: [
                                  "https://www.googleapis.com/auth/drive.readonly",
                                ],
                                multiselect: true,
                                navHidden: false,
                                authImmediate: false,
                                upload: true,
                                viewId: "DOCS",
                              },
                              on: { change: _vm.change, loaded: _vm.loaded },
                            },
                            [
                              _c("font-awesome-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.bottom-start",
                                    value: {
                                      content:
                                        "Adicione um arquivo do Google Drive.",
                                      delay: { show: 1000, hide: 100 },
                                    },
                                    expression:
                                      "{\n                        content: 'Adicione um arquivo do Google Drive.',\n                        delay: { show: 1000, hide: 100 }\n                      }",
                                    modifiers: { "bottom-start": true },
                                  },
                                ],
                                staticClass: "cursor-pointer pl-4",
                                staticStyle: {
                                  "font-size": "35px !important",
                                  height: "20px",
                                },
                                attrs: { icon: _vm.icons.faGoogleDrive },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.id
                      ? _c(
                          "div",
                          { staticClass: "mt-2 w-full" },
                          [
                            _c("content-media-list", {
                              attrs: { medias: _vm.medias },
                              on: { deleteMedia: _vm.deleteMedia },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "col-span-4 ml-2 ",
                      staticStyle: {
                        "border-left": "1px solid rgba(0, 0, 0, 0.2)",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-col gap-4 px-2 h-full" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "vs-select",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "selecione-turmas-para-compartilhar"
                                    ),
                                    multiple: "",
                                    autocomplete: "",
                                    label: "Turmas:",
                                    noData:
                                      "Escolha uma ou mais turmas para compartilhar.",
                                  },
                                  model: {
                                    value: _vm.selectedClasses,
                                    callback: function ($$v) {
                                      _vm.selectedClasses = $$v
                                    },
                                    expression: "selectedClasses",
                                  },
                                },
                                _vm._l(_vm.classesList, function (item) {
                                  return _c("vs-select-item", {
                                    key: item,
                                    attrs: {
                                      value: item.id,
                                      text:
                                        item.title +
                                        "<br/><label class='text-sm'>  " +
                                        _vm.studentsQuantity(item) +
                                        " Aluno(s)</label>",
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("selected_classes"),
                                      expression:
                                        "errors.has('selected_classes')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("selected_classes"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { key: _vm.selectThemeKey },
                            [
                              _vm.creatingTheme
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-full flex items-end gap-2",
                                    },
                                    [
                                      _c("vs-input", {
                                        ref: "theme-input",
                                        staticClass: "flex-grow",
                                        attrs: {
                                          label: _vm.$t("titulo-do-tema"),
                                        },
                                        model: {
                                          value: _vm.creatingThemeTitle,
                                          callback: function ($$v) {
                                            _vm.creatingThemeTitle = $$v
                                          },
                                          expression: "creatingThemeTitle",
                                        },
                                      }),
                                      _c("vs-button", {
                                        staticClass:
                                          "flex-grow-0 flex-shrink-0",
                                        attrs: {
                                          color: "danger",
                                          type: "border",
                                          "icon-pack": "feather",
                                          icon: "icon-x",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.creatingTheme = false
                                            _vm.theme = null
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "vs-select",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        disabled: !_vm.hasAnyClassSelected,
                                        placeholder: _vm.$t(
                                          "selecione-o-tema-para-vincular"
                                        ),
                                        autocomplete: "",
                                        label: "Tema:",
                                        noData:
                                          "Escolha uma ou mais tema para vincular ao conteúdo.",
                                      },
                                      on: { input: _vm.checkThemeCreation },
                                      model: {
                                        value: _vm.theme,
                                        callback: function ($$v) {
                                          _vm.theme = $$v
                                        },
                                        expression: "theme",
                                      },
                                    },
                                    [
                                      _c("vs-select-item", {
                                        attrs: {
                                          value: "create",
                                          text: "<b>+ Criar novo tema</b>",
                                        },
                                      }),
                                      _vm._l(
                                        _vm.themesList,
                                        function (item, index) {
                                          return _c("vs-select-item", {
                                            key: index,
                                            attrs: {
                                              value: item.model.id,
                                              text: item.text,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "class_content_topic_id"
                                      ),
                                      expression:
                                        "errors.has('class_content_topic_id')",
                                    },
                                  ],
                                  staticClass: "text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("class_content_topic_id")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("agendar-para"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("datetime", {
                                  staticClass:
                                    "flex-auto w-8/12 mr-1 tour-config-4",
                                  attrs: {
                                    "input-class":
                                      "vs-inputx vs-input--input normal",
                                    phrases: { ok: "Ok", cancel: "Cancelar" },
                                    type: "date",
                                    format: "dd/MM/yyyy",
                                    "value-zone": "America/Sao_Paulo",
                                    placeholder: _vm.$t("data"),
                                    auto: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.startChanged("date")
                                    },
                                  },
                                  model: {
                                    value: _vm.start_at.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.start_at, "date", $$v)
                                    },
                                    expression: "start_at.date",
                                  },
                                }),
                                _c("datetime", {
                                  staticClass: "flex-auto w-4/12",
                                  attrs: {
                                    "input-class":
                                      "vs-inputx vs-input--input normal",
                                    phrases: { ok: "Ok", cancel: "Cancelar" },
                                    placeholder: _vm.$t("hora"),
                                    type: "time",
                                    "minute-step": "5",
                                    format: "HH:mm",
                                    "value-zone": "America/Sao_Paulo",
                                    "use24-hour": "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.startChanged("time")
                                    },
                                  },
                                  model: {
                                    value: _vm.start_at.time,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.start_at, "time", $$v)
                                    },
                                    expression: "start_at.time",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.isType("A")
                            ? _c(
                                "div",
                                [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("pontuacao")) + ":"),
                                  ]),
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      max: "100",
                                      step: "1",
                                    },
                                    model: {
                                      value: _vm.score,
                                      callback: function ($$v) {
                                        _vm.score = $$v
                                      },
                                      expression: "score",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isType("A")
                            ? _c("div", [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("data-de-entrega"))),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("datetime", {
                                      staticClass:
                                        "flex-auto w-8/12 mr-1 tour-config-4",
                                      attrs: {
                                        "input-class":
                                          "vs-inputx vs-input--input normal",
                                        phrases: {
                                          ok: "Ok",
                                          cancel: "Cancelar",
                                        },
                                        type: "date",
                                        format: "dd/MM/yyyy",
                                        "value-zone": "America/Sao_Paulo",
                                        placeholder: _vm.$t("data-0"),
                                        auto: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.dueChanged("date")
                                        },
                                      },
                                      model: {
                                        value: _vm.due_at.date,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.due_at, "date", $$v)
                                        },
                                        expression: "due_at.date",
                                      },
                                    }),
                                    _c("datetime", {
                                      staticClass: "flex-auto w-4/12",
                                      attrs: {
                                        "input-class":
                                          "vs-inputx vs-input--input normal",
                                        phrases: {
                                          ok: "Ok",
                                          cancel: "Cancelar",
                                        },
                                        placeholder: _vm.$t("hora-0"),
                                        type: "time",
                                        "minute-step": "5",
                                        format: "HH:mm",
                                        "value-zone": "America/Sao_Paulo",
                                        "use24-hour": "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.dueChanged("time")
                                        },
                                      },
                                      model: {
                                        value: _vm.due_at.time,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.due_at, "time", $$v)
                                        },
                                        expression: "due_at.time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "flex w-full justify-end gap-2" },
                            [
                              _c("vs-button", { on: { click: _vm.create } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("action.save")) + " "
                                ),
                              ]),
                              _c(
                                "vs-button",
                                { attrs: { type: "border", to: "/contents" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("action.cancel")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
          false
            ? _c(
                "tab-content",
                {
                  staticClass: "mb-5",
                  attrs: {
                    title: _vm.$t("correcao"),
                    icon: "feather icon-check",
                  },
                },
                [_c("span", [_vm._v("...")])]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("enviar-arquivo"),
            active: _vm.showUploadModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.showUploadModal = $event
            },
          },
        },
        [
          _c("content-file-upload", {
            attrs: { uploadUrl: _vm.fileUploadUrl },
            on: { input: _vm.fileUploaded },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("endereco-web"), active: _vm.showUploadLink },
          on: {
            "update:active": function ($event) {
              _vm.showUploadLink = $event
            },
          },
        },
        [
          _vm.showUploadLink
            ? _c("url-input", {
                on: {
                  input: function ($event) {
                    _vm.uploadLink = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "w-full flex flex-row justify-end items-center pt-2",
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { disabled: _vm.isEmpty(_vm.uploadLink) },
                  on: { click: _vm.uploadLinkMedia },
                },
                [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }